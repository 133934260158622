<template>
  <div :id="item.name" class="game-item effect-hover" :class="{ maintain: item.maintain }" @click="handleOpenGame">
    <div class="item">
      <div class="game-item__wrapper">
        <BaseImg
          :id="`${item?.name}-image`"
          :src="item?.image || item?.img"
          :alt="item?.name"
          lazy
          class="thumbnail"
          crossorigin="anonymous"
        />
      </div>
      <div class="game-item__hover">
        <div class="game-content">
          <div class="play-text" @click="handleOpenGame">Chơi ngay</div>
          <div v-if="item.is_demo" class="try-now" @click="handleOpenGame">Chơi thử</div>
        </div>
      </div>
    </div>
    <div
      v-if="!item.maintain && jackpot(item.partner_game_id) && PATH_SHOW_ICON.includes($route.path)"
      class="game-item__money"
    >
      <div class="money-content">
        <BaseImg class="icon" :src="'/assets/images/icon/amount.webp'" alt="icons-vnd" />
        <AnimatedNumber :number="jackpot(item.partner_game_id)" />
        <BaseImg class="light-effect" :src="'/assets/images/game/bg-light-effect.png'" alt="icon-light-effect" />
      </div>
    </div>
    <client-only>
      <BaseImg
        v-if="getThumbImage()"
        class="game-item__tag"
        :src="getThumbImage()"
        :alt="`tags ${item.tags.name}`"
      />
    </client-only>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import debounce from 'lodash/debounce'
import BaseImg from '~/components/common/base-img.vue'
import AnimatedNumber from '~/components/common/animate-count-up.vue'
import useNavigationGame from '~/composables/useNavigationGame'
import { PATH_IMAGES_ICON } from '~/constants/path'
import { useAppStore } from '~/store/app'

const { $device, $pinia } = useNuxtApp()

const store = useAppStore($pinia)
const { currentUser } = storeToRefs(store)

const PATH_SHOW_ICON = ['/game/no-hu', '/', '/game/slots', '/game/ban-ca']

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})
const jackpot = (gameid) => {
  if (gameid) {
    return store.jackpot[gameid]
  }
  return null
}
const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()

const handleOpenGame = debounce(() => {
  navigationCheckLoggedInAndOpenGame(props.item)
}, 50)

const getThumbImage = () => {
  return props.item.tags.img || ''
}
</script>

<style lang="scss" scoped src="assets/scss/components/common/game-item.scss"></style>
